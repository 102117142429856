import React from 'react';
import './App.css';
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import BuzzHome from './components/Buzz/BuzzHome';
import TermsOfService from './components/TndL/Terms-of-service';
import PrivacyPolicy from './components/TndL/PrivacyPolicy';
import DeepLinks from './components/DeepLinks/DeepLinks';
import BuzzCrew from './components/Buzz/BuzzCrew';
import { HelmetProvider } from 'react-helmet-async';

function App() {
    return (
        <>
            <HelmetProvider>
                <CssBaseline />
                <Routes>
                    <Route path='/' element={<BuzzHome />} />
                    <Route path='/terms-of-service' element={<TermsOfService />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/buzz-crew' element={<BuzzCrew />} />
                    <Route path='/feed/post' element={<DeepLinks />} />
                    <Route path='/profile/user' element={<DeepLinks />} />
                </Routes>

            </HelmetProvider>

        </>

    );
}

export default App;